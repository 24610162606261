import {
  PButton,
  PDivider,
  PHeading,
  PSegmentedControl,
  PSegmentedControlItem,
  PSelectWrapper,
  PSwitch,
  PText,
  PTextFieldWrapper,
} from '@porsche-design-system/components-react';
import {
  gridGap,
  themeDarkNotificationError,
  themeDarkNotificationErrorSoft,
  themeDarkNotificationInfo,
  themeDarkNotificationSuccess,
  themeLightContrastMedium,
  themeLightNotificationError,
  themeLightNotificationInfo,
  themeLightNotificationSuccess,
  themeLightNotificationWarning,
  themeLightStateFocus,
} from '@porsche-design-system/components-react/styles';
import { Spacer, Spinner, styled, theme } from '@porsche-kado/ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { COMMON_NAMESPACE, NAMESPACES } from '../../../config/i18n';
import {
  AnalyticsEvent,
  AnalyticsGranularity,
  useAnalyticsQuery,
} from '../../../graphql';

type Filter = {
  app: string;
  event: AnalyticsEvent;
  dateFrom: string;
  dateTo: string;
  granularity: AnalyticsGranularity;
  unique: boolean;
};

const Form = styled('form', {
  display: 'grid',
  gridGap: gridGap,
  gridAutoColumns: '1fr 1fr 200px 200px 1fr 1fr auto',
  gridAutoFlow: 'column',
  alignItems: 'end',
});

export const AnalyticsDashboard = (): JSX.Element => {
  const { t } = useTranslation(NAMESPACES);
  const [filter, setFilter] = useState<Filter>();

  const { register, control, handleSubmit } = useForm<Filter>({
    defaultValues: {
      app: '',
      event: AnalyticsEvent.Login,
      dateFrom: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      dateTo: dayjs().format('YYYY-MM-DD'),
      granularity: AnalyticsGranularity.Day,
      unique: false,
    },
  });

  const onSubmit: SubmitHandler<Filter> = (data) => {
    setFilter({
      app: data.app,
      event: data.event,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      granularity: data.granularity,
      unique: data.unique,
    });
  };

  return (
    <>
      <PHeading role="heading" size="large" aria-level={1} tag="h1">
        {t('admin.analytics.headline')}
      </PHeading>

      <Spacer mb="$medium" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <PSelectWrapper label={t('admin.analytics.app')}>
          <select required {...register('app', { required: true })}>
            <option hidden />
            <option value="dashboard">
              {t('app.dashboard', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="field-force-management">
              {t('app.ffm', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="greenland">
              {t('app.greenland', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="porsche-strategy-2030">
              {t('app.porscheStrategy2030', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="network-planning">
              {t('app.networkPlanning', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="retail-design">
              {t('app.retailDesign', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="survey">
              {t('app.survey', { ns: COMMON_NAMESPACE })}
            </option>
            <option value="mystery-shopping">
              {t('app.mysteryShopping', { ns: COMMON_NAMESPACE })}
            </option>
          </select>
        </PSelectWrapper>

        <PSelectWrapper label={t('admin.analytics.event')}>
          <select required {...register('event', { required: true })}>
            <option hidden />
            {Object.values(AnalyticsEvent).map((event) => (
              <option key={event} value={event}>
                {t(`admin.analytics.events.${event}`)}
              </option>
            ))}
          </select>
        </PSelectWrapper>

        <PTextFieldWrapper label={t('admin.analytics.dateFrom')}>
          <input
            type="date"
            required
            {...register('dateFrom', { required: true })}
          />
        </PTextFieldWrapper>

        <PTextFieldWrapper label={t('admin.analytics.dateTo')}>
          <input
            type="date"
            required
            {...register('dateTo', { required: true })}
          />
        </PTextFieldWrapper>

        <div>
          <label>{t('admin.analytics.granularity')}</label>

          <Spacer mb="$xSmall" />

          <Controller
            name="granularity"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PSegmentedControl
                aria-label={t('admin.analytics.option')}
                style={{ gridAutoFlow: 'column' }}
                value={field.value}
                onBlur={field.onBlur}
                ref={field.ref}
                onUpdate={(event) =>
                  field.onChange(event.detail.value as AnalyticsGranularity)
                }
              >
                {Object.values(AnalyticsGranularity).map((granularity) => (
                  <PSegmentedControlItem key={granularity} value={granularity}>
                    {t(`admin.analytics.granularities.${granularity}`)}
                  </PSegmentedControlItem>
                ))}
              </PSegmentedControl>
            )}
          />
        </div>

        <div>
          <label>{t('admin.analytics.unique')}</label>
          <Spacer mb="$xSmall" />
          <Controller
            name="unique"
            control={control}
            render={({ field }) => (
              <PSwitch
                style={{ paddingBottom: '13px', paddingTop: '13px' }}
                hideLabel
                checked={field.value}
                onBlur={field.onBlur}
                ref={field.ref}
                onUpdate={(event) => field.onChange(event.detail.checked)}
              >
                {t('admin.analytics.unique')}
              </PSwitch>
            )}
          />
        </div>

        <PButton type="submit" variant="primary" icon="search">
          {t('admin.analytics.analyze')}
        </PButton>
      </Form>

      <Spacer mb="$medium" />

      <PDivider />

      <Spacer mb="$medium" />

      {filter && <Report filter={filter} />}
    </>
  );
};

const Report = ({ filter }: { filter: Filter }) => {
  const { t } = useTranslation();

  const dateFormat = {
    [AnalyticsGranularity.Day]: 'YYYY-MM-DD',
    [AnalyticsGranularity.Month]: 'YYYY-MM',
    [AnalyticsGranularity.Year]: 'YYYY',
  };

  const colors = [
    themeDarkNotificationErrorSoft,
    themeLightNotificationError,
    themeDarkNotificationError,
    themeLightStateFocus,
    themeLightNotificationInfo,
    themeDarkNotificationInfo,
    themeLightNotificationSuccess,
    themeDarkNotificationSuccess,
    themeLightNotificationWarning,
    themeLightContrastMedium,
  ];

  const { isLoading, data } = useAnalyticsQuery(
    { input: filter },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const chartData = data?.analytics
    ?.reduce(
      (prev, curr) => {
        if (prev.find((item) => item.eventDate === curr.eventDate)) {
          return prev.map((item) => {
            if (item.eventDate === curr.eventDate) {
              return {
                ...item,
                total: item.total + curr.eventCount,
                [curr.role ?? 'N/A']: curr.eventCount,
              };
            }

            return item;
          });
        }

        return [
          ...prev,
          {
            eventDate: curr.eventDate,
            total: curr.eventCount,
            [curr.role ?? 'N/A']: curr.eventCount,
          },
        ];
      },
      [] as {
        eventDate: string;
        total: number;
        [key: string]: number | string;
      }[],
    )
    .map((item) => ({
      ...item,
      eventDate: dayjs(item.eventDate).unix(),
    }));

  const ticks = [dayjs(filter.dateFrom).unix()];
  while (ticks[ticks.length - 1] < dayjs(filter.dateTo).unix()) {
    ticks.push(
      dayjs
        .unix(ticks[ticks.length - 1])
        .add(1, filter.granularity.toLowerCase())
        .unix(),
    );
  }

  const roles = [...new Set(data?.analytics?.map(({ role }) => role))];

  if (isLoading) {
    return <Spinner />;
  }

  if (!data?.analytics?.length) {
    return <PText align="center">{t('admin.analytics.noData')}</PText>;
  }

  return (
    <ResponsiveContainer width="100%" height={400} debounce={250}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="eventDate"
          type="number"
          domain={['dataMin', 'dataMax']}
          tickCount={12}
          ticks={ticks}
          tickFormatter={(value) =>
            dayjs.unix(value).format(dateFormat[filter.granularity])
          }
        />
        <YAxis allowDecimals={false} />
        <Tooltip
          labelFormatter={(value) =>
            dayjs.unix(value).format(dateFormat[filter.granularity])
          }
        />
        {roles.length > 1 && (
          <>
            <Legend />
            <Line
              dataKey="total"
              stroke={theme.colors.contrastMedium.toString()}
            />
          </>
        )}
        {roles.map((role, index) => (
          <Line key={role} dataKey={role ?? 'N/A'} stroke={colors[index]} />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
