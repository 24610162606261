import { type IconColor, PIcon } from '@porsche-design-system/components-react';
import { styled } from '@stitches/react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
});

const Header = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
});

const Actions = styled(motion.div, {
  overflow: 'hidden',
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gridGap: '$small',
  alignItems: 'center',
  marginRight: '$xSmall',
  padding: '$xSmall',
});

const Icon = styled(motion(PIcon), {
  cursor: 'pointer',

  variants: {
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});

export const Fab = ({
  title,
  children,
  color,
  disabled,
}: {
  title: ReactNode;
  children: ReactNode;
  color?: IconColor;
  disabled?: boolean;
}) => (
  <Header>
    {title}
    {children && (
      <Button color={color} disabled={disabled}>
        {children}
      </Button>
    )}
  </Header>
);

export const FabAction = styled(PIcon, {
  cursor: 'pointer',
  borderRadius: '$small',

  '&:hover': {
    backgroundColor: '$hover',
  },
});

const Button = ({
  onToggle,
  stayOpen = false,
  children,
  color,
  disabled = false,
  ...props
}: {
  onToggle?: (open: boolean) => void;
  stayOpen?: boolean;
  children: ReactNode;
  color?: IconColor;
  disabled?: boolean;
}) => {
  const [isExpanded, setExpand] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  // avoid closing the actions if mouseOver and click occur after each other
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setExpandDebounced = useCallback(
    debounce(setExpand, 500, {
      leading: true,
      trailing: false,
    }),
    [setExpand],
  );

  const autoCollapse = useRef(0);

  useEffect(() => {
    let mounted = true;
    if (!hasFocus && !stayOpen) {
      autoCollapse.current = window.setTimeout(
        () => mounted && setExpand(false),
        1000,
      );
    } else if (autoCollapse.current) {
      window.clearTimeout(autoCollapse.current);
    }
    return () => {
      mounted = false;
    };
  }, [hasFocus, stayOpen]);

  const toggleRef = useRef(onToggle);
  toggleRef.current = onToggle;
  useEffect(() => {
    toggleRef.current?.(isExpanded);
  }, [isExpanded]);

  return (
    <Wrapper
      aria-disabled={disabled}
      onPointerDown={(evt) => evt.stopPropagation()}
      onPointerEnter={() => {
        setHasFocus(true);
      }}
      onPointerLeave={() => {
        setHasFocus(false);
      }}
      {...props}
    >
      <Actions
        initial={{ width: 0 }}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={{
          expanded: {
            width: 'auto',
            opacity: 1,
          },
          collapsed: {
            width: 0,
            opacity: 0,
          },
        }}
        transition={{ ease: 'backInOut' }}
      >
        {children}
      </Actions>
      <Icon
        data-testid="fab-toggle"
        name="menu-dots-vertical"
        color={color}
        animate={{ rotate: isExpanded ? 90 : 0 }}
        disabled={disabled}
        {...(!disabled && {
          onClick: () => setExpandDebounced((e) => !e),
          onPointerOver: () => setExpandDebounced(true),
        })}
      />
    </Wrapper>
  );
};
