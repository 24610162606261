import {
  PButton,
  PButtonPure,
  PSelectWrapper,
} from '@porsche-design-system/components-react';
import { Column } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ActionGroup, Popover } from '../..';

export const SelectFilter = <T,>({
  column,
  options,
  i18n,
}: {
  column: Column<T>;
  options: { label: string; value: string | number }[];
  i18n: {
    filterLabel: string;
    optionAll: string;
    buttonReset: string;
    buttonFilter: string;
  };
}): JSX.Element => {
  const [isVisible, setVisibility] = useState(false);
  const filterValue = column.getFilterValue() as string;
  const [selectValue, setSelectValue] = useState(filterValue);

  useEffect(() => {
    setSelectValue(filterValue);
  }, [filterValue]);

  return (
    <Popover
      target={
        <PButtonPure
          type="button"
          active={!!filterValue}
          aria-label={i18n.filterLabel}
          size="x-small"
          icon="filter"
          onClick={() => setVisibility((isVisible) => !isVisible)}
          role="button"
          hideLabel
        />
      }
      open={isVisible}
      onChange={setVisibility}
      placement="right-start"
    >
      <>
        <PSelectWrapper label={i18n.filterLabel} aria-label={i18n.filterLabel} hideLabel filter role="combobox">
          <select
            name={i18n.filterLabel}
            value={selectValue || undefined}
            onChange={(e) => {
              setSelectValue(e.currentTarget.value);
            }}
          >
            <option value="">{i18n.optionAll}</option>
            {options.map(({ label, value }) => (
              <option key={label} value={value}>
                {label}
              </option>
            ))}
          </select>
        </PSelectWrapper>

        <ActionGroup spacing="medium">
          <PButton
            type="button"
            variant="secondary"
            icon="close"
            role="button"
            onClick={() => {
              column.setFilterValue(undefined);
              setVisibility(false);
            }}
          >
            {i18n.buttonReset}
          </PButton>
          <PButton
            role="button"
            type="button"
            onClick={() => {
              column.setFilterValue(selectValue || undefined);
              setVisibility(false);
            }}
          >
            {i18n.buttonFilter}
          </PButton>
        </ActionGroup>
      </>
    </Popover>
  );
};
