import { useMutation } from '@tanstack/react-query';
import {
  useCreateDashboardEmailNotificationMutation,
  useDeleteDashboardEmailNotificationMutation,
  useUpdateDashboardEmailNotificationMutation,
} from '../../graphql';
import { useUpdateNotificationCache } from '../../hooks';

export const useNotificationSettings = () => {
  const cacheHelper = useUpdateNotificationCache();

  return useMutation(
    async ({
      notification,
      settings,
    }: {
      notification?: { id: string };
      settings: {
        isActive: boolean;
        adhoc?: boolean | null;
      };
    }) => {
      const shouldCreateNotification = settings.isActive && !notification?.id;
      const shouldUpdateNotification = settings.isActive && !!notification?.id;
      const shouldDeleteNotification = !settings.isActive && !!notification?.id;

      switch (true) {
        case shouldCreateNotification: {
          const { createDashboardEmailNotification } =
            await useCreateDashboardEmailNotificationMutation.fetcher({
              input: {
                adhoc: settings.adhoc,
              },
            })();

          cacheHelper.create(createDashboardEmailNotification);

          break;
        }

        case shouldUpdateNotification: {
          const { updateDashboardEmailNotification } =
            await useUpdateDashboardEmailNotificationMutation.fetcher({
              input: {
                id: notification!.id,
                adhoc: settings.adhoc,
              },
            })();

          cacheHelper.update(
            notification!.id,
            updateDashboardEmailNotification,
          );

          break;
        }

        case shouldDeleteNotification: {
          await useDeleteDashboardEmailNotificationMutation.fetcher({
            id: notification!.id,
          })();

          cacheHelper.delete(notification!.id);

          break;
        }
      }

      return true;
    },
  );
};
