import { createFileRoute } from '@tanstack/react-router';
import { Trans } from 'react-i18next';
import { COMMON_NAMESPACE } from '../config/i18n';
import { Profile } from '../pages';

export const Route = createFileRoute('/profile')({
  component: () => <Profile />,
  beforeLoad: () => {
    return {
      breadcrumb: () => (
        <Trans i18nKey="profile.headline" ns={COMMON_NAMESPACE} />
      ),
    };
  },
});
