import {
  PButton,
  PCheckboxWrapper,
  PHeading,
  PText,
} from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../config/i18n';
import { DashboardEmailNotificationsQuery } from '../../graphql';
import { useNotificationSettings } from './useNotificationSettings';

dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const SupportRequestNotifications = ({
  notification,
}: {
  notification?: DashboardEmailNotificationsQuery['dashboardEmailNotifications'][0];
}) => {
  const { t } = useTranslation(NAMESPACES);

  const [notificationSettings, setNotificationSettings] = useState<{
    isActive: boolean;
  }>({
    isActive: !!notification,
  });

  const { mutate: saveNotificationSettings, isLoading: isSaving } =
    useNotificationSettings();

  return (
    <div>
      <PHeading role="heading" size="small" aria-level={3} tag="h3">
        {t('profile.supportRequestNotifications')}
      </PHeading>

      <Spacer mb="$medium" />

      <PText>{t('profile.supportRequestNotificationsDescription')}</PText>

      <Spacer mb="$medium" />

      <form
        onSubmit={(e) => {
          e.preventDefault();

          saveNotificationSettings({
            notification,
            settings: {
              isActive: notificationSettings.isActive,
              adhoc: true,
            },
          });
        }}
      >
        <PCheckboxWrapper
          label={t('profile.supportRequestNotificationsConfirm')}
        >
          <input
            type="checkbox"
            name="statusNotifications"
            disabled={isSaving}
            checked={notificationSettings.isActive}
            onChange={(e) =>
              setNotificationSettings((curr) => ({
                ...curr,
                isActive: e.target.checked,
              }))
            }
          />
        </PCheckboxWrapper>

        <Spacer mb="$medium" />

        <PButton
          type="submit"
          role="button"
          variant="primary"
          loading={isSaving}
        >
          {t('common:action.save')}
        </PButton>
      </form>
    </div>
  );
};
