import {
  PHeading,
  PIcon,
  PLinkPure,
} from '@porsche-design-system/components-react';
import { Modal, styled } from '@porsche-kado/ui';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { hasPorscheDesignSystemBrowserSupport } from '../helpers';

const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  gap: '$medium',
});

export const BrowserSupport = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);

  if (hasPorscheDesignSystemBrowserSupport()) {
    return null;
  }

  return (
    <Modal
      open={isModalOpen}
      onDismiss={() => setIsModalOpen(false)}
      backdrop="shading"
      aria={{ 'aria-label': t('browserSupport.title') }}
    >
      <Content>
        <PIcon
          name="warning"
          size="inherit"
          color="notification-warning"
          style={{ width: '10rem' }}
        />

        <PHeading role="heading" slot="header" size="large" tag="h2">
          {t('browserSupport.title')}
        </PHeading>

        <div>
          <Trans i18nKey="browserSupport.content">
            The browser you are using is no longer supported. It would be best
            to directly switch to the latest version of{' '}
            <PLinkPure
              icon="none"
              href="https://google.com/chrome/"
              target="_blank"
              rel="nofollow noopener"
              underline
            >
              Google Chrome
            </PLinkPure>
            ,
            <PLinkPure
              icon="none"
              href="https://mozilla.org/firefox/new/"
              target="_blank"
              rel="nofollow noopener"
              underline
            >
              Mozilla Firefox
            </PLinkPure>
            or
            <PLinkPure
              icon="none"
              href="https://microsoft.com/edge/"
              target="_blank"
              rel="nofollow noopener"
              underline
            >
              Microsoft Edge
            </PLinkPure>
            .
          </Trans>
        </div>
      </Content>
    </Modal>
  );
};
