import {
  PSelectWrapper,
  PSelectWrapperProps,
} from '@porsche-design-system/components-react';

enum Format {
  'DD.MM.YYYY' = 'DD.MM.YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'YYYY/MM/DD' = 'YYYY/MM/DD',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'YYYY.MM.DD' = 'YYYY.MM.DD',
  'YYYY.DD.MM' = 'YYYY.DD.MM',
}

type DateFormatSelectProps = PSelectWrapperProps &
  Pick<React.SelectHTMLAttributes<HTMLSelectElement>, 'defaultValue'>;

export const DateFormatSelect = ({
  defaultValue,
  ...props
}: DateFormatSelectProps): JSX.Element => (
  <PSelectWrapper {...props}>
    <select name="dateFormat" defaultValue={defaultValue}>
      {Object.values(Format).map((format) => (
        <option key={format} value={format}>
          {format}
        </option>
      ))}
    </select>
  </PSelectWrapper>
);
