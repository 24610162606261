import { useQueryClient } from '@tanstack/react-query';
import {
  CreateDashboardEmailNotificationMutation,
  DashboardEmailNotificationsQuery,
  UpdateDashboardEmailNotificationMutation,
  useDashboardEmailNotificationsQuery,
} from '../graphql';

export const useUpdateNotificationCache = () => {
  const queryClient = useQueryClient();

  return {
    create: (
      notification: CreateDashboardEmailNotificationMutation['createDashboardEmailNotification'],
    ) => {
      queryClient.setQueryData<DashboardEmailNotificationsQuery>(
        useDashboardEmailNotificationsQuery.getKey(),
        (cache) => {
          if (!cache) {
            return cache;
          }

          return {
            ...cache,
            dashboardEmailNotifications: [
              ...cache.dashboardEmailNotifications,
              notification,
            ],
          };
        },
      );
    },
    update: (
      id: string,
      notification: UpdateDashboardEmailNotificationMutation['updateDashboardEmailNotification'],
    ) => {
      queryClient.setQueryData<DashboardEmailNotificationsQuery>(
        useDashboardEmailNotificationsQuery.getKey(),
        (cache) => {
          if (!cache) {
            return cache;
          }

          return {
            ...cache,
            dashboardEmailNotifications: cache.dashboardEmailNotifications.map(
              (n) => (n.id === id ? notification : n),
            ),
          };
        },
      );
    },
    delete: (id: string) => {
      queryClient.setQueryData<DashboardEmailNotificationsQuery>(
        useDashboardEmailNotificationsQuery.getKey(),
        (cache) => {
          if (!cache) {
            return cache;
          }

          return {
            ...cache,
            dashboardEmailNotifications:
              cache.dashboardEmailNotifications.filter((n) => n.id !== id),
          };
        },
      );
    },
  };
};
